import React from "react"
import Message from "../components/Message"
import Login from "../components/Login"
import Footer from "../components/Footer"

export default function LoginView() {
  return (
    <>
      <div className="container dark">
        <div className="section">
          <Message />
          <Login />
          <Footer />
        </div>
      </div>
    </>
  )
}
