import { createState } from "state-pool"

//The current environment of the Blind Insight application
export const CurrentEnvironment = createState("")

// The current user object if we have one
export const CurrentUser = createState({})

// Hash of organization.id to the organization object
export const Organizations = createState({})
export const CurrentOrgId = createState()
export const CurrentOrg = createState()

// Hash of dataset.id to the dataset object
export const Datasets = createState({})

// Hash of schema.id to the schema
export const Schemas = createState({})

// Hash of dataset.id to a list of schema.id
export const DatasetSchemas = createState({})

// Hash of the schema.id to the schema with hashed field keys
export const HashedSchemas = createState({})

// List of active filters
export const CurrentFilters = createState([])

// Navigational state that persists between view changes
export const PersistedNavigation = createState({ schemas: "records" })

// Object to use as a default import
export function Store(name) {
  const stores = {
    currentUser: CurrentUser,
    organizations: Organizations,
    datasets: Datasets,
    schemas: Schemas,
    datasetSchemas: DatasetSchemas,
    hashedSchemas: HashedSchemas,
    currentFilters: CurrentFilters,
  }
  if (!stores[name]) throw new Error(`${name} is not a valid store`)
  return stores[name]
}

export default Store
