import React, { useMemo } from "react"
import { useParams, Link } from "react-router-dom"
import { Button } from "./Button"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"

// import EditIcon from "../icons/EditIcon.png"
// import PlusIcon from "../icons/PlusIcon.svg"
import colors from "../colors"

import { Datasets, Schemas, DatasetSchemas } from "../lib/store"

const styles = {
  headCell: ({ border }) => ({
    color: "rgba(0, 0, 0, 1)",
    background: "rgba(245, 245, 245, 1)",
    fontSize: "13px",
    fontWeight: 500,
    paddingTop: 1,
    paddingBottom: 1,
    borderBottom: border ? "1px solid rgba(225, 207, 255, 1)" : undefined,
  }),
  cell: {
    fontSize: "13px",
    color: "rgba(51, 51, 51, 1)",
    borderBottom: "1px solid rgba(225, 207, 255, 1)",
  },
  button: {
    height: "49px",
    borderRadius: "100px",
    fontWeight: 600,
    fontSize: "18px",
    color: colors.gray1,
    background: "rgba(40, 22, 98, 1)",
    "&:hover": {
      background: "rgba(40, 22, 98, 1)",
    },
    textTransform: "none",
  },
  input: {
    "& input": {
      height: "30px",
      color: colors.pink90,
    },
    background:
      "linear-gradient(0deg, #F0F0F0, #F0F0F0), linear-gradient(0deg, #AEAEAE, #AEAEAE)",
    borderRadius: "95px",
    "& fieldset": {
      border: "1px solid rgba(174, 174, 174, 1)",
      borderRadius: "95px",
    },
  },
}

export default function DatasetDetail() {
  // Glboal state
  const [datasets] = Datasets.useState()
  const [schemas] = Schemas.useState()
  const [datasetSchemas] = DatasetSchemas.useState()

  // Path params
  const { datasetId } = useParams()

  // What to display
  const [dataset, setDataset] = React.useState({})
  const [schemasList, setSchemasList] = React.useState([])
  const border = useMemo(() => schemas?.length > 0, [schemas])

  // Pick the dataset and the schemas that belong to it
  React.useEffect(() => {
    setDataset(datasets[datasetId])
    setSchemasList((datasetSchemas[datasetId] || []).map((id) => schemas[id]))
  }, [datasetId, datasets, schemas, datasetSchemas])

  console.debug("Rendering DatasetDetail", {
    datasetId,
    dataset,
    schemasList,
    datasets,
    schemas,
    datasetSchemas,
  })

  return (
    <>
      <div className="view rows">
        <div id="dataset-detail-header" className="row">
          <h2>{dataset?.name} &rarr; schemas</h2>
        </div>
        <div className="button-group">
          <div className="button-container">
            <Link to={`/datasets/${datasetId}/schemas/create`}>
              <Button
                buttonStyle="square"
                size="small"
                icon="add"
                text="New schema"
                id="btn-schema-new"
                title="Create a schema"
              />
            </Link>
          </div>
        </div>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "10px",
            boxShadow: "8px 8px 12px 3px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={styles.headCell({ border })}>Schema</TableCell>
                <TableCell sx={styles.headCell({ border })}>Fields</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schemasList.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={styles.cell}>
                    <Link to={`/datasets/${datasetId}/schemas/${row.id}`}>
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell sx={styles.cell}>
                    {(row.schema?.properties &&
                      Object.keys(row.schema.properties).length) ||
                      "Unknown"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}
