import React, { useState } from "react"
import { Form } from "react-router-dom"
import { useRouteLoaderData } from "react-router-dom"
import TextField from "../components/TextField"
import { Button } from "../components/Button"
import { onChange } from "../lib/util"

export default function ProfileView() {
  const user = useRouteLoaderData("auth")
  let [editMode, setEditMode] = useState("")

  if (!user) return null
  return (
    <div className="view rows">
      <h2>Edit profile</h2>
      <div className="info-groups rows">
        <div className="info-group rows">
          <h6>Login & Security</h6>
          {(editMode === "email" && (
            <>
              <Form className="columns">
                Email address:{" "}
                <TextField
                  name="slug"
                  placeholder={user.email}
                  value={user.email}
                  onChange={onChange(user.email)}
                  size="small"
                  type="text"
                  required
                />
                <Button
                  icon="save"
                  className="status-green"
                  size="small"
                  buttonStyle="inline"
                  type="submit"
                  onClick={() => {
                    setEditMode("")
                  }}
                />
              </Form>
            </>
          )) || (
            <>
              <div className="columns">
                Email address: {user.email}
                <Button
                  icon="edit"
                  size="small"
                  buttonStyle="inline"
                  onClick={() => {
                    setEditMode("email")
                  }}
                />
              </div>
            </>
          )}
          {(editMode === "password" && (
            <>
              <Form>
                <TextField
                  name="old-password"
                  placeholder="old password"
                  size="small"
                  type="password"
                  helperText="Enter your old password"
                  required
                  label="Old password"
                />
                <TextField
                  name="password"
                  placeholder="new password"
                  size="small"
                  type="password"
                  helperText="Enter your new password"
                  required
                  label="New password"
                />
                <div className="button-group">
                  <Button
                    icon="save"
                    text="save"
                    size="small"
                    buttonStyle="default"
                    type="submit"
                    className="status-green"
                    onClick={() => {
                      setEditMode("")
                    }}
                  />
                </div>
              </Form>
            </>
          )) || (
            <>
              <div className="columns">
                Password: ********
                <Button
                  icon="edit"
                  size="small"
                  buttonStyle="inline"
                  onClick={() => {
                    setEditMode("password")
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="info-group rows">
          <h6>Personal info</h6>
          {(editMode === "name" && (
            <>
              <Form className="columns">
                Full name:{" "}
                <TextField
                  name="name"
                  placeholder={user.first_name}
                  size="small"
                  type="text"
                  required
                />
                <Button
                  icon="save"
                  className="status-green"
                  size="small"
                  buttonStyle="inline"
                  type="submit"
                  onClick={() => {
                    setEditMode("")
                  }}
                />
              </Form>
            </>
          )) || (
            <>
              <div className="columns">
                Full name: {user.first_name} {user.last_name}
                <Button
                  icon="edit"
                  size="small"
                  buttonStyle="inline"
                  onClick={() => {
                    setEditMode("name")
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
