import React from "react"
import { useNavigate, Form, Link } from "react-router-dom"
import { Button } from "./Button"
import TextField from "./TextField"
import { Alert } from "@mui/material"
import { UserLogin } from "../lib/user"
import { onChange } from "../lib/util"
// Styles
import "./Login.css"

export default function Login() {
  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [error, setError] = React.useState(null)
  const [setSuccess] = React.useState(null)
  const navigate = useNavigate()

  const login = async (event) => {
    event.preventDefault()
    console.log("Logging user in...")
    let success
    try {
      success = await UserLogin(username, password)
    } catch (e) {
      success = false
    }

    if (!success) {
      setPassword("")
      setSuccess(null)
      setError("Login failed")
    } else {
      setError(null)
      setUsername("")
      setPassword("")
      console.log("Login success")
      navigate("/")
    }
  }

  const redirectToRegister = () => {
    navigate("/register")
  }

  return (
    <div className="form-container">
      <div className="logo-vertical" />
      <div className="title-text">
        <h4>Sign In</h4>
      </div>
      {error && <Alert severity="error">{error}</Alert>}
      <Form onSubmit={login} className="full-width rows">
        <TextField
          autoComplete="username"
          label="Email address"
          value={username}
          onChange={onChange(setUsername)}
          placeholder="Email Address"
          helperText={error || "Your email address is your username"}
          className="full-width"
          type="email"
          name="email"
          id="email"
          required
          validate
        />
        <TextField
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={onChange(setPassword)}
          placeholder="Password"
          className="full-width"
          name="password"
          id="password"
          status={error && "error"}
          helperText={
            error || (
              <>
                {" "}
                <Link to="/accounts/forgot-password">Forgot Password</Link>
              </>
            )
          }
          required
        />
        <div className="button-group form-action row">
          <Button size="small" text="Sign In" type="submit" className="light" />
        </div>
        <div>
          New? <Link onClick={redirectToRegister}>Register here</Link>
        </div>
      </Form>
    </div>
  )
}
