import React from "react"
import { useNavigate, Form, Link } from "react-router-dom"
import Box from "@mui/material/Box"
import Checkbox from "./CheckBox"
import { Button } from "./Button"
import TextField from "./TextField"
import { Alert } from "@mui/material"
import { UserRegister } from "../lib/user"
import { onChange } from "../lib/util"
import TOU from "../views/TOU"
import Modal from "./Modal"

// Styles
import "./Login.css"

export default function Login() {
  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [error, setError] = React.useState(null)
  const [success, setSuccess] = React.useState(null)
  const navigate = useNavigate()

  const handleRegister = async (event) => {
    event.preventDefault()

    const { email, password, fullName, organizationName } =
      event.target.elements
    let success
    try {
      success = await UserRegister(
        email.value,
        password.value,
        fullName.value,
        organizationName.value,
      )
    } catch (e) {
      success = false
    }

    if (!success) {
      setError("Registration failed")
      setSuccess(null)
    } else {
      console.log("Registration success")
      setError(null)
      setSuccess(
        "Registration successful. Please check your email for an activation link.",
      )
      navigate("/accounts/register-confirm")
    }
  }
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const redirectToLogin = () => {
    navigate("/login")
  }

  return (
    <div className="form-container">
      <div className="logo-vertical" />
      <div className="toggle-heading">
        <h4>Sign Up</h4>
      </div>

      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}

      <Form onSubmit={handleRegister} className="full-width rows">
        <TextField
          autoComplete="username"
          className="full-width"
          name="email"
          placeholder="Email Address"
          type="email"
          onChange={onChange(setUsername)}
          value={username}
          status={error && "error"}
          helperText={error || "Your email address is your username"}
          required
        />
        <TextField
          autoComplete="password"
          className="full-width"
          name="password"
          placeholder="Password"
          type="password"
          onChange={onChange(setPassword)}
          status={error && "error"}
          helperText={error || "Enter a password"}
          value={password}
          required
        />
        <TextField
          className="full-width"
          name="organizationName"
          placeholder="Organization Name"
          size="large"
          type="text"
          status={error && "error"}
          helperText={error || " "}
          required
        />
        <TextField
          className="full-width"
          name="fullName"
          placeholder="Your Full Name"
          size="large"
          type="text"
          status={error && "error"}
          helperText={error || " "}
          required
        />

        <div>
          <Checkbox name="tos" required /> I agree to the{" "}
          <Link onClick={handleOpen}>Terms of Use.</Link>
        </div>

        <div>
          <Checkbox name="robot" required /> I am not a robot.
        </div>

        <Box className="form-action full-width">
          <Button
            size="large"
            text="Sign Up"
            className="light"
            type="submit"
          ></Button>
        </Box>
        <div className="toggle-heading">
          <>Already a user? </>
          <Link onClick={redirectToLogin}>Sign In</Link>
        </div>
      </Form>
      <Modal isOpen={open} onClose={handleClose} heading="Terms of use">
        <TOU />
      </Modal>
    </div>
  )
}
