import React from "react"
import { useRouteLoaderData } from "react-router-dom"
import AccountTabs from "@/components/AccountTabs"
import Profile from "@/components/Profile"

export default function ProfileView() {
  const user = useRouteLoaderData("auth")

  if (!user) return null
  return (
    <>
      <AccountTabs />
      <Profile />
    </>
  )
}
