import React from "react"
import "./Modal.css"
import PropTypes from "prop-types"
import { Button } from "./Button"

const Modal = ({ isOpen, onClose, children, heading, body, className }) => {
  if (!isOpen) return null
  const handleChildElementClick = (e) => {
    e.stopPropagation()
  }

  return (
    <div className="modal-bg" onClick={onClose}>
      <div
        className={`modal ${className}`}
        onClick={(e) => handleChildElementClick(e)}
      >
        <div className="button-group">
          <Button buttonStyle="inline" icon="close" onClick={onClose} />
        </div>
        <h6>{heading}</h6>
        {body}
        {children}
      </div>
    </div>
  )
}

Modal.propTypes = {
  heading: PropTypes.string.isRequired,
}

export default Modal
