import React from "react"
import {
  Route,
  Navigate,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  createRoutesFromElements,
} from "react-router-dom"
import AccountView from "./views/AccountView"
import AuthRequired from "./components/AuthRequired"
import { AuthRequiredError } from "./components/AuthRequired"
import DebugView from "./views/DebugView"
import SidebarLayout from "./components/SidebarLayout"
import FetchDatasetsSchemas from "./components/FetchDatasetsSchemas"
import ProfileView from "./views/ProfileView"
import UsersView from "./views/UsersView"

// import Home from "./views/Home"
import CurrentOrganization from "./components/CurrentOrganization"
import AccountsActivateView from "./views/AccountsActivateView"
import ForgotPasswordView from "./views/ForgotPasswordView"
import ResetPasswordView from "./views/ResetPasswordView.js"
import RegisterConfirmView from "./views/RegisterConfirmView"
import DatasetsListView from "./views/DatasetsListView"
import DatasetDetailView from "./views/DatasetDetailView"
import SchemaDetailView from "./views/SchemaDetailView"
import SchemaCreateView from "./views/SchemaCreateView"
import SchemaRecordsListView from "./views/SchemaRecordsListView"
import OrgNameView from "./views/SelectOrgNameView"
import QuickStartView from "./views/QuickStartView"
import PageNotFoundView from "./views/PageNotFoundView"
import LoginView from "./views/LoginView"
import RegisterView from "./views/RegisterView"
import * as Sentry from "@sentry/react"

const SENTRY_DSN = process.env.BLINDINSIGHT_UI_SENTRY_DSN

// Only initialize Sentry if the DSN is set.
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/.*\.blindinsight\.io\//],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const App = createRoutesFromElements(
  <Route exact path="/">
    <Route path="*" element={<PageNotFoundView />} />
    <Route path="login" element={<LoginView />} />
    <Route path="register" element={<RegisterView />} />
    <Route
      id="auth"
      element={<AuthRequired />}
      loader={AuthRequired.loader}
      errorElement={<AuthRequiredError />}
    >
      <Route index element={<Navigate to="/datasets" />} />
      <Route
        element={<CurrentOrganization />}
        loader={CurrentOrganization.loader}
      >
        <Route
          element={<FetchDatasetsSchemas />}
          loader={FetchDatasetsSchemas.loader}
        >
          <Route element={<SidebarLayout />}>
            <Route path="datasets">
              <Route index element={<DatasetsListView />} />
              <Route path=":datasetId">
                <Route index element={<DatasetDetailView />} />
                <Route exact path="schemas">
                  <Route
                    index
                    element={<Navigate to="/datasets/:datasetId" />}
                  />
                  <Route exact path="create">
                    <Route index element={<SchemaCreateView />} />
                  </Route>
                  <Route id="schema" path=":schemaId">
                    <Route index element={<SchemaDetailView />} />
                    <Route exact path="records">
                      <Route index element={<SchemaRecordsListView />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="account" element={<AccountView />} />
            <Route path="users" element={<UsersView />} />
            <Route path="profile" element={<ProfileView />} />
          </Route>
        </Route>
        <Route path="debug" element={<DebugView />} />
        {/* The orgname and quickstart pages should not be at the top level - probably under /register or /signup */}
        <Route path="orgname" element={<OrgNameView />} />
        <Route path="quickstart" element={<QuickStartView />} />
      </Route>
    </Route>
    <Route path="accounts">
      <Route path="activate" element={<AccountsActivateView />} />
      <Route path="register-confirm" element={<RegisterConfirmView />} />
      <Route path="forgot-password" element={<ForgotPasswordView />} />
      <Route path="reset-password" element={<ResetPasswordView />} />
    </Route>
  </Route>,
)

export default App
