import React, { useMemo, useState } from "react"

import {
  Link,
  useParams,
  useSearchParams,
  useNavigate,
  useRouteLoaderData,
  Form,
  useLocation,
} from "react-router-dom"

import { Box, Tab, Tabs, Alert } from "@mui/material"
import { Button } from "./Button"
import { Icon } from "./Icon"
import TextField from "./TextField"
import {
  Datasets,
  HashedSchemas,
  Schemas,
  PersistedNavigation,
  CurrentFilters,
} from "../lib/store"
import { Api } from "../lib/api"
import { isEmpty } from "../lib/util"
import { DataGrid } from "@mui/x-data-grid"

export default function SchemaRecordsList() {
  const [error, setError] = React.useState(null)
  const user = useRouteLoaderData("auth")
  const [persistedNavigation] = PersistedNavigation.useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const limit = parseInt(searchParams.get("limit") || 25)
  const offset = parseInt(searchParams.get("offset") || 0)
  const { datasetId, schemaId } = useParams()
  const [datasets] = Datasets.useState()
  const [schemas] = Schemas.useState()
  const [currentFilters] = CurrentFilters.useState()
  const navigate = useNavigate()
  const location = useLocation()
  const api = new Api()

  // List of current records in view
  const [records, setRecords] = useState([])
  // Mapping of hashed schemas
  const [hashedSchemas] = HashedSchemas.useState()
  // Find the selected dataset
  const dataset = useMemo(() => datasets[datasetId], [datasets, datasetId])
  // Find the selected schema
  const schema = useMemo(() => schemas[schemaId], [schemas, schemaId])
  // Get the hashed (decrypted) schema
  const hashedSchema = useMemo(
    () => hashedSchemas[schemaId],
    [hashedSchemas, schemaId],
  )
  // Decryption toggle
  const [encrypted, setEncrypted] = useState(true)
  // Can decrypt schema?
  const [canDecryptSchema, setCanDecryptSchema] = useState(true)

  const isDataOwner = useMemo(() => {
    return !user?.email?.startsWith("data_requester")
  }, [user])

  // Can decrypt records?
  const [canDecryptRecords, setCanDecryptRecords] = useState(() => {
    // TODO(jathan): This is a temporary hack to disallow decryption for data_requester for now.
    if (!isDataOwner) return false
    // TODO(shakefu): Get decrypt permission state from the API when we
    // support that metadata call
    // Default to allowing decryption, once
    return true
  })
  // Records count
  const count = useMemo(() => {
    if (!records?.count) {
      return -1
    }
    return records.count
  }, [records])
  // Records to decrypt
  const [missingRecords, setMissingRecords] = useState({})
  // Decrypted records
  const [decryptedRecords, setDecryptedRecords] = useState({})
  // Records pending decryption
  const [pendingRecords, setPendingRecords] = useState({})
  // Schema pending decryption
  const [pendingSchema, setPendingSchema] = useState(false)
  const properties = useMemo(() => schema?.schema?.properties || {}, [schema])

  const filterMenuItems = useMemo(() => {
    const entries = Object.entries(properties)
    if (!entries.length) return []
    // return entries.map(([name, value]) => <MenuItem value={name}>{name}</MenuItem>)
    return entries.map(([name]) => (
      <option key={name} value={name}>
        {name}
      </option>
    ))
  }, [properties])

  const recordMap = useMemo(() => {
    if (pendingSchema) {
      console.debug("recordMap waiting for schema...")
      return
    }
    console.debug("Creating recordMap...")
    let inverted = {}
    for (const record of records) {
      inverted[record.id] = record.data
    }
    if (encrypted) {
      console.debug("Returning encrypted recordMap", inverted)
      return inverted
    }

    let changed = false
    let missing = {}
    for (const id in missingRecords) {
      missing[id] = missingRecords[id]
    }
    for (const record of records) {
      if (!decryptedRecords[record.id]) {
        if (pendingRecords[record.id]) continue
        if (!missing[record.id]) {
          changed = true
          missing[record.id] = record
        }
      }
    }
    if (changed) {
      console.debug("Need to decrypt missing records", missing)
      setMissingRecords(missing)
    }

    console.debug("Returning decryptedRecords", decryptedRecords)
    return decryptedRecords
  }, [
    records,
    decryptedRecords,
    missingRecords,
    pendingRecords,
    pendingSchema,
    encrypted,
  ])

  // This effect will attempt to decrypt the schema if it's not already decrypted.
  // If it fails, it won't retry.
  React.useEffect(() => {
    if (!schema) return
    // If we're not decrypting, get out early
    if (!canDecryptSchema) return
    if (pendingSchema) return
    if (hashedSchemas[schema.id]) return
    setPendingSchema(true)
    const api = new Api()
    console.debug("Decrypting schema for SchemaRecordsList", schema.id)
    api
      .schemasDecrypt(schema)
      .then(() => {
        setPendingSchema(false)
      })
      .catch(() => {
        setPendingSchema(false)
        setCanDecryptSchema(false)
      })
  }, [
    hashedSchemas,
    schema,
    pendingSchema,
    setPendingSchema,
    canDecryptSchema,
    setCanDecryptSchema,
  ])

  const [hasProxy, setHasProxy] = useState(true)

  // This effect will attempt to decrypt the records if "encrypted" is false.
  // If it fails, it should revert to displaying the encrypted records.
  React.useEffect(() => {
    // If we're not decrypting, get out early
    if (encrypted) return

    // If we aren't allowed to decrypt, get out early
    if (!canDecryptRecords) return

    // Calculate actual missing records vs what is pending in an API query
    let missing = []
    if (Object.keys(missingRecords).length > 0) {
      console.debug("Merging missing and pending records", {
        missingRecords,
        pendingRecords,
      })
      let pending = {}
      for (const id in missingRecords) {
        if (!pendingRecords[id]) {
          missing.push(missingRecords[id])
          pending[id] = true
        }
      }
      for (const id in pendingRecords) {
        pending[id] = true
      }

      // If we didn't find any changes, get out
      if (missing.length === 0) return

      // Clear missing records that were set
      console.debug("Clearing missing records", missing)
      setMissingRecords({})

      // Update the pending record state
      console.debug("Updating pending records")
      setPendingRecords(pending)
    }

    console.debug("Missing records", missing)

    // If we're already pending decryption, get out early
    if (missing.length === 0) return

    const api = new Api()
    api
      .recordsDecrypt(missing)
      .then((data) => {
        console.debug("Raw decrypted data", data)
        let newDecrypted = {}
        for (const record of data) {
          newDecrypted[record.id] = record.data
        }
        for (const id in decryptedRecords) {
          newDecrypted[id] = decryptedRecords[id]
        }
        console.debug("Updating decrypted records", decryptedRecords)
        setDecryptedRecords(newDecrypted)
      })
      .catch((error) => {
        console.log("Error decrypting records", error)
        setMissingRecords({})
        if (error?.status === 404) {
          console.log("Decrypt records not found")
          setError("Decrypted records not found, please refresh the page.")
          return
        }
        setEncrypted(true)
        if (error?.status === 403) {
          console.log("Decrypt permission denied")
          setError("You do not have permission to decrypt these records.")
          return setCanDecryptRecords(false)
        }
        // TODO(shakefu): This 405 is a wonky status code.
        if (error?.status === 405) {
          console.log("Decrypt not available on API")
          //commented this out so there isn't a duplicate message displayed in the UI (see line 375-ish)
          //setError(<Fragment>Decryption unavailable, please <a href='https://docs.blindinsight.io/getting-started/using-the-blind-proxy/#using-the-blind-proxy' target='new'>run the Blind Proxy</a> to decrypt.</Fragment>)
          return setHasProxy(false)
        }
      })
  }, [
    encrypted,
    setEncrypted,
    canDecryptRecords,
    setCanDecryptRecords,
    missingRecords,
    setMissingRecords,
    decryptedRecords,
    setDecryptedRecords,
    pendingRecords,
    setPendingRecords,
  ])

  React.useEffect(() => {
    if (persistedNavigation.schemas !== "records") {
      PersistedNavigation.updateValue((persistedNav) => {
        persistedNav.schemas = "records"
      })
    }
  }, [persistedNavigation])

  // Render nothing if we don't have a user
  if (!user) return null
  // Render a not found if we couldn't find a schema in state
  if (!schema)
    return (
      <Box p={3}>
        <Alert severity="error">Schema not found</Alert>
      </Box>
    )

  const toggleEncryption = () => {
    if (!canDecryptRecords && encrypted) {
      console.debug("Can't toggle encryption, can't decrypt")
      return
    }
    console.debug("Toggling encryption")
    setEncrypted(!encrypted)
  }

  const handlePaginate = (pageModel, extra) => {
    console.warn("handlePaginate", "pageModel", pageModel, "extra", extra)
    const newPage = pageModel.page
    const newLimit = pageModel.pageSize
    let params = {
      offset: newPage * newLimit,
      limit: newLimit,
    }
    setSearchParams(params)
  }

  const fieldNames = useMemo(() => {
    if (!recordMap) return []

    if (!hashedSchema || isEmpty(hashedSchema)) {
      // Flatten the recordMap to get the field names and remove duplicates as a set.
      const encryptedKeys = Object.values(recordMap).map((record) =>
        Object.keys(record),
      )
      const flattenedKeys = encryptedKeys.flat()
      const uniqueKeys = new Set(flattenedKeys)
      // console.log("fieldNames flattenedKeys=", flattenedKeys)
      return Array.from(uniqueKeys)
    } else {
      return Object.keys(hashedSchema || {}).map((key) => {
        if (encrypted) return key
        return hashedSchema[key]?.name
      })
    }
  }, [recordMap, hashedSchema, encrypted])

  // missing here is the idField in the row - JP just can't
  const thecolumns = fieldNames.map((name) => ({
    field: name,
    type: properties[name]?.type,
    width: 80,
    editable: false,
  }))

  const therows = records.map((record) => {
    if (encrypted) return { id: record.id, ...record.data }
    return { id: record.id, ...decryptedRecords[record.id] }
  })

  // const thecolumns = columns.concat(idColumn).reverse() //reverse is a hack because you can only pin w/ pro - oops
  const [filters, setFilters] = React.useState([
    { label: "", operator: "", value: "", value2: "" },
  ])
  const handleAddFilter = () => {
    setFilters([...filters, { label: "", operator: "", value: "", value2: "" }])
  }

  const handleRemoveFilter = () => {
    setFilters([...filters.slice(0, -1)])
  }

  const handleInputChange = (e, index) => {
    const values = [...filters]
    if (e.target.name === "label") {
      values[index].label = e.target.value
    } else if (e.target.name === "operator") {
      values[index].operator = e.target.value
    } else if (e.target.name === "value") {
      values[index].value = e.target.value
    } else {
      values[index].value2 = e.target.value
    }
    setFilters(values)
  }

  const handleSearch = () => {
    let newFilters = []
    filters.map(function (filter) {
      let newValue = {}
      let expression = {}
      if (filter.value2) {
        newValue = filter.value + filter.operator + filter.value2
      } else if (filter.operator != "=") {
        newValue = filter.operator + filter.value
      } else {
        newValue = filter.value
      }
      if (filter.label && newValue) {
        expression.label = filter.label
        expression.value = newValue
        newFilters = [...newFilters, expression]
      }
    })
    CurrentFilters.setValue(newFilters)
  }

  React.useEffect(() => {
    // Refresh the records when the filters change.
    const updateRecords = async () => {
      const resp = await api.recordsSearch(
        schemaId,
        currentFilters,
        limit,
        offset,
      )
      setRecords(resp)
    }
    updateRecords()
  }, [currentFilters, schemaId, limit, offset])

  const clearFilters = () => {
    setFilters([{ label: "", operator: "=", value: "", value2: "" }])
    CurrentFilters.setValue([])
    navigate(location)
  }

  // TODO(jathan): Trigger decryptRecords on load to get proxy overlay to show. Remove this when we have proxy detect endpoint.
  React.useEffect(() => {
    toggleEncryption()
  }, [])

  return (
    <>
      <Tabs value="records">
        <Tab
          label="Schema"
          value="schema"
          component={Link}
          to={`/datasets/${datasetId}/schemas/${schemaId}`}
        />
        <Tab label="Records" value="records" disabled />
      </Tabs>
      <div className="view rows">
        <div className="section-header rows" id="records-list-header">
          <h2>
            <Link to={`/datasets/${datasetId}`}>{dataset.name} &rarr; </Link>
            {schema.name} &rarr; records
          </h2>
          {schema.description}
        </div>
        <div className="row full-width columns" id="filters">
          {hasProxy === false && (
            <div className="overlay">
              <Alert severity="info">
                Please{" "}
                <a
                  href="https://docs.blindinsight.io/getting-started/using-the-blind-proxy/#using-the-blind-proxy"
                  target="new"
                >
                  download and run the Blind Proxy
                </a>{" "}
                to search and decrypt locally. Feel free to get in touch if you
                need assistance.
              </Alert>
            </div>
          )}
          <div className="rows full-width">
            <div className="row full-width">
              <h6>Filter by</h6>
            </div>
            <div className="row columns full-width">
              <Form id="filter-set">
                <div className="row columns">
                  <div className="grid">
                    {filters.map((filter, index) => (
                      <div key={index} className="columns">
                        <select
                          id="filter-select"
                          name="label"
                          label="Column"
                          onChange={(e) => handleInputChange(e, index)}
                          className="drop-down"
                          value={filter.label ?? ""}
                          title="Select a column to filter by"
                        >
                          <option>Column</option>
                          {filterMenuItems}
                        </select>
                        {filter.label &&
                          properties[filter.label]?.type === "number" && (
                            <>
                              <select
                                className="drop-down"
                                id="operator-select"
                                name="operator"
                                onChange={(e) => handleInputChange(e, index)}
                                size="small"
                                defaultValue="="
                                title="You must be an authorized data requester or data owner and run the Blind Proxy to perform searches"
                              >
                                <option value="=" title="equals">
                                  =
                                </option>
                                <option value=">" title="greater than">
                                  &gt;
                                </option>
                                <option value="<" title="less than">
                                  &lt;
                                </option>
                                <option
                                  value=">="
                                  title="greater than or equal to"
                                >
                                  &ge;
                                </option>
                                <option
                                  value=">="
                                  title="less than or equal to"
                                >
                                  &le;
                                </option>
                                <option value="~" title="between">
                                  &lt; &gt;
                                </option>
                              </select>
                              <TextField
                                type="number"
                                name="value"
                                value={filter.searchValue}
                                onChange={(e) => handleInputChange(e, index)}
                                placeholder="Value"
                                size="small"
                                title="Enter a value to filter by"
                              />
                            </>
                          )}
                        {filter.operator && filter.operator === "~" && (
                          <TextField
                            type="number"
                            name="value2"
                            value={filter.searchValue2}
                            onChange={(e) => handleInputChange(e, index)}
                            placeholder="Value"
                            size="small"
                            title="Enter a max value to filter by"
                          />
                        )}
                        {filter.label &&
                          properties[filter.label]?.type === "string" && (
                            <>
                              <div className="large">=</div>
                              <TextField
                                value={filter.searchValue}
                                onChange={(e) => handleInputChange(e, index)}
                                name="value"
                                placeholder="Value"
                                size="small"
                                title="Value to filter by"
                              />
                            </>
                          )}
                      </div>
                    ))}
                  </div>

                  {filters.length > 1 && (
                    <Button
                      icon="subtract"
                      buttonStyle="inline"
                      onClick={handleRemoveFilter}
                    />
                  )}
                  {filters.length < filterMenuItems.length &&
                    filters.length > 0 && (
                      <Button
                        icon="add"
                        buttonStyle="inline"
                        onClick={handleAddFilter}
                      />
                    )}
                  <Button
                    type="button"
                    text="Filter"
                    icon="filter"
                    size="small"
                    buttonName="submit"
                    buttonStyle="dark"
                    onClick={handleSearch}
                    title="Filter data"
                  />
                  <Button
                    type="button"
                    text="Clear filters"
                    icon="close"
                    size="small"
                    onClick={clearFilters}
                    buttonStyle="inline"
                    title="Clear filters"
                  />
                </div>
              </Form>

              <div className="button-group">
                <div className="button-container">
                  <Icon
                    size="large"
                    className={encrypted ? "status-green" : "status-red"}
                    type={encrypted ? "lock" : "unlock"}
                  />
                  <Button
                    buttonStyle="square"
                    size="small"
                    onClick={toggleEncryption}
                    text={encrypted ? "Decrypt results" : "Encrypt results"}
                    disabled={canDecryptRecords === false}
                    title={
                      canDecryptRecords
                        ? "Toggle encryption"
                        : "You must be an authorized data owner and decrypt locally using the proxy."
                    } // replace this w/ user creds
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row full-width">
          {error && (
            <div className="row">
              {" "}
              <Alert severity="error">{error}</Alert>
            </div>
          )}

          <div className="data-grid-container">
            <DataGrid
              columns={thecolumns}
              rows={therows}
              disableColumnMenu
              pagination
              paginationMode="server"
              onPaginationModelChange={handlePaginate}
              rowCount={count}
              initialState={{
                rowCount: count,
                pagination: { paginationModel: { pageSize: 25 } },
                innedColumns: { left: ["id"] },
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
