import React from "react"
import { Tabs, Tab } from "@mui/material"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"

export default function AccountTabs() {
  const location = useLocation()

  const tabSelection = () => {
    const url = location.pathname
    const urlParts = url.split("/")
    return urlParts[urlParts.length - 1]
  }

  return (
    <Tabs value={tabSelection()}>
      <Tab
        label="Account"
        value="account"
        component={Link}
        to="/account"
        disabled={tabSelection() == "account"}
      />
      <Tab
        label="Users"
        value="users"
        component={Link}
        to="/users"
        disabled={tabSelection() == "users"}
      />
      <Tab
        label="Profile"
        value="profile"
        component={Link}
        to="/profile"
        disabled={tabSelection() == "profile"}
      />
    </Tabs>
  )
}
