import React from "react"

export default function ResetPassword() {
  return (
    <React.Fragment>
      <div className="container dark">
        <div className="section">
          <div className="message">
            <h1>Thank you signing up for Blind Insight!</h1>
            <p>Please check your email for an activation link!</p>
          </div>
          <div className="form-container">
            <div className="logo-vertical" />
          </div>
        </div>
        <div className="footer dark full-width">
          &copy; 2024, <a href="https://blindinsight.com">Blind Insight</a> |{" "}
          <a href="https://docs.blindinsight.io">View docs</a>
        </div>
      </div>
    </React.Fragment>
  )
}
