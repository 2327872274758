import React from "react"
import { useNavigate, useRouteLoaderData, Link } from "react-router-dom"
import Menu from "@mui/material/Menu"
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state"
import { UserLogout } from "../lib/user"
import { CurrentOrg } from "../lib/store"
import { Button } from "./Button"

export default function ProfileMenu() {
  const user = useRouteLoaderData("auth")
  const navigate = useNavigate()

  const logout = () => {
    UserLogout()
    navigate("/login")
  }

  const [currentOrg] = CurrentOrg.useState()

  if (!user) return null

  return (
    <>
      <div className="user-menu full-width">
        <PopupState variant="popover" popupId="user-profile-menu">
          {(popupState) => (
            <React.Fragment>
              <Button
                {...bindTrigger(popupState)}
                icon="chevron-right"
                text="Account settings"
                buttonStyle="inline"
                className="dark"
              />
              <Menu
                {...bindMenu(popupState)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "bottom", horizontal: "right" }}
                className="menu"
              >
                <div className="menu-item">
                  Organization:
                  <br />
                  <select
                    id="org-select"
                    label="org-select"
                    className="drop-down"
                    defaultValue={""}
                  >
                    <option value="">{currentOrg.name}</option>
                  </select>
                </div>
                <Link to="/account" className="menu-item">
                  Account
                </Link>
                <a
                  href="https://docs.blindinsight.io"
                  target="new"
                  rel="noreferrer"
                  className="menu-item"
                >
                  API Docs
                </a>
                <div className="menu-item">
                  <Button
                    icon="help"
                    text="Help"
                    buttonStyle="inline"
                    onClick={() => {
                      window.Intercom("show")
                    }}
                  />
                </div>
                <div className="menu-item">
                  <Button
                    text="Log out"
                    buttonStyle="inline"
                    onClick={() => {
                      popupState.close()
                      logout()
                    }}
                  />
                </div>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </div>
    </>
  )
}
