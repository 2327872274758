import React from "react"
import { Outlet, useLoaderData, useRouteLoaderData } from "react-router-dom"

import { Datasets, Schemas, CurrentOrgId } from "../lib/store"
import { Api } from "../lib/api"
import { isEmpty } from "../lib/util"

export default function FetchDatasetsSchemas() {
  const user = useRouteLoaderData("auth")
  const [datasets] = useLoaderData()
  if (!datasets || !user) return <></>
  return <Outlet />
}

FetchDatasetsSchemas.loader = async () => {
  // If we're missing either datasets or schemas, fetch them
  if (isEmpty(Datasets.getValue()) || isEmpty(Schemas.getValue())) {
    const currentOrgId = CurrentOrgId.getValue()
    const api = new Api()
    const datasets = await api.datasetsList(currentOrgId)
    const schemas = await api.schemasList()
    return [datasets, schemas]
  }

  // Otherwise use stored state
  return [Datasets.getValue(), Schemas.getValue()]
}
