import React from "react"
import AccountTabs from "../components/AccountTabs"
import Account from "../components/Account"

export default function AccountView() {
  return (
    <>
      <AccountTabs />
      <Account />
    </>
  )
}
