import React from "react"

export default function PageNotFound() {
  return (
    <React.Fragment>
      <div className="container dark">
        <div className="section">
          <div className="message">
            <h1>404 - Not Found</h1>
            <p>Oops! The page you are looking for does not exist.</p>
          </div>
          <div className="form-container">
            <div className="logo-vertical" />
          </div>
        </div>
        <div className="footer dark full-width">
          &copy; 2024, <a href="https://blindinsight.com">Blind Insight</a> |{" "}
          <a href="https://docs.blindinsight.io">View docs</a>
        </div>
      </div>
    </React.Fragment>
  )
}
