import { Store, CurrentUser } from "./store"
import { Api } from "./api"

export async function UserLogin(username, password) {
  const api = new Api()
  const resp = await api.accountsLogin(username, password)
  console.log("Logged in", resp)
  if (!resp) return false

  CurrentUser.updateValue((user) => {
    user.username = username
  })

  const user = await api.currentUserRetrieve()
  console.log("Retrieved currentUser", user)
  if (!user) return false
  return true
}

export async function UserRetrieve() {
  const api = new Api()
  console.log("Retrieving currentUser")
  const user = await api.currentUserRetrieve()
  console.log("Retrieved currentUser", user)
  if (!user) return false
  return true
}

export async function UserIsLoggedIn() {
  // const api = new Api()
  // const hasAuth = Boolean(api.getAuthUsername())
  const hasAuth = await UserRetrieve()
  console.log("UserIsLoggedIn", "hasAuth=", hasAuth)
  return [Boolean(CurrentUser.getValue()?.id), hasAuth]
}

export async function UserLogout() {
  // TODO: This is probably not a great way to manage state
  Store("currentUser").setValue({})
  Store("datasets").setValue({})
  Store("schemas").setValue({})
  Store("datasetSchemas").setValue({})
  Store("hashedSchemas").setValue({})

  // TODO(shakefu): Don't hard code this, it should be handled somewhere sane
  // TODO(jathan): Sane? I'll show you sane! 😜
  const api = new Api()
  await api.accountsLogout()
}

export async function UserRegister(email, password, fullName, orgName) {
  const api = new Api()
  // console.log("Registering new user...", email, password, fullName, orgName)
  const resp = await api.accountsRegister(email, password, fullName, orgName)
  console.log("Registered new user", resp)
  if (!resp) return false
  return true
}
