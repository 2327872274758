import PropTypes from "prop-types"
import React from "react"
import "./Button.css"
import { Icon } from "./Icon"

export const Button = ({
  size,
  className,
  onClick,
  text,
  icon,
  type,
  id,
  buttonName,
  disabled,
  buttonStyle,
  title,
}) => {
  return (
    <button
      className={`button ${size} ${buttonStyle} ${className} ${disabled}`}
      onClick={onClick}
      type={type}
      id={id}
      name={buttonName}
      disabled={disabled}
      title={title}
    >
      <div className="button-text">{text}</div>
      {icon && (
        <>
          <Icon type={icon} />
        </>
      )}
    </button>
  )
}

Button.propTypes = {
  size: PropTypes.oneOf(["large", "inline", "small"]),
  icon: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
}
