import React from "react"

export default function Footer() {
  return (
    <div className="footer dark full-width">
      &copy; 2024, <a href="https://blindinsight.com">Blind Insight</a> |{" "}
      <a href="https://docs.blindinsight.io">View docs</a>
    </div>
  )
}
