import React from "react"
import Message from "@/components/Message"
import Register from "../components/Register"
import Footer from "../components/Footer"

export default function RegisterView() {
  return (
    <>
      <div className="container dark">
        <div className="section">
          <Message />
          <Register />
          <Footer />
        </div>
      </div>
    </>
  )
}
