import React from "react"
import {
  Form,
  useLocation,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom"
import Modal from "../components/Modal"
import { Icon } from "../components/Icon"
import { Api } from "../lib/api"
import AccountTabs from "@/components/AccountTabs"
import {
  Alert,
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
// import CurrentOrganization from "./CurrentOrganization"
// import Checkbox from "./CheckBox"
import { Button } from "../components/Button"
import { CurrentOrg } from "../lib/store"
import TextField from "../components/TextField"

export default function UsersView(props) {
  console.log("OrganizationCard props=", props)
  console.log("OrganizationCard open?", open)
  const [organization] = CurrentOrg.useState()
  const currentUser = useRouteLoaderData("auth")
  const [userEditOpen, setUserEditOpen] = React.useState(false)
  const [userCreateOpen, setUserCreateOpen] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState({}) //state for table
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const api = new Api()

  const handleUserEditOpen = () => {
    setUserEditOpen(true)
  }

  const handleUserEditClose = () => {
    setUserEditOpen(false)
  }

  const handleUserCreateOpen = () => {
    setUserCreateOpen(true)
  }

  const handleUserCreateClose = () => {
    setUserCreateOpen(false)
  }

  const handleUserCreateSubmit = async (event) => {
    event.preventDefault()
    const { email, isAdmin } = event.target.elements
    // console.log("handleUserCreateSubmit", "email=", email.value, "isAdmin", isAdmin.checked)
    let success
    try {
      success = await api.organizationsInvitationsCreate(
        organization.id,
        email.value,
        isAdmin.checked,
      )
    } catch (e) {
      success = false
    }
    if (!success) {
      console.log("Failed to create user")
      setError("Failed to create user")
      setSuccess(null)
    } else {
      console.log("User created successfully")
      setError(null)
      setUserCreateOpen(false)
      setSuccess("User created successfully")
      navigate(location)
    }
  }

  const handleUserEditSubmit = async (event) => {
    event.preventDefault()
    const { userId, isAdmin } = event.target.elements
    let success
    try {
      success = await api.organizationsUsersPartialUpdate(
        organization.id,
        userId.value,
        isAdmin.checked,
      )
    } catch (e) {
      success = false
    }
    if (!success) {
      console.log("Failed to update user")
      setSuccess(null)
      setError("Failed to update user")
    } else {
      console.log("User updated successfully")
      setError(null)
      setUserEditOpen(false)
      setSuccess("User updated successfully")
      navigate(location)
    }
  }

  const orgOwner = React.useMemo(() => {
    // console.log("orgOwner useMemo", organization)
    return organization.owner.organization_user
  }, [organization])

  const currentOrgUser = React.useMemo(() => {
    // console.log("currentOrgUser useMemo", organization)
    return organization.organization_users.find(
      (ou) => ou.user.email === currentUser.email,
    )
  }, [organization, currentUser])

  const orgUsers = React.useMemo(() => {
    // console.log("orgUsers useMemo", organization)
    return organization.organization_users
  }, [organization])

  const getUserRole = (orgUser) => {
    // console.log("getUserRole", orgUser)
    if (!orgUser) return

    if (orgUser.user.email === orgOwner.user.email) {
      return "Owner"
    } else if (orgUser.is_admin) {
      return "Data Owner"
    }
    return "Data Requester"
  }

  const shouldDisableIsAdmin = (orgUser) => {
    // console.log("allowIsAdminEdit", orgUser)
    if (!orgUser || !orgUser?.user) return

    if (orgUser.user.email === currentUser.email) {
      return true
    } else if (orgUser.user.email === orgOwner.user.email) {
      return true
    } else if (!currentOrgUser?.is_admin) {
      return true
    }

    return false
  }

  return (
    <>
      <AccountTabs />
      <div className="view rows">
        <div className="section-header">
          <h2>{organization.name} user administration</h2>
          Organization Owner: {orgOwner.user.email}
        </div>
        <div className="full-width button-group">
          <Button
            type="button"
            buttonStyle="square"
            size="small"
            onClick={handleUserCreateOpen}
            text="Add User"
            icon="add"
          />
        </div>
        <TableContainer component={Paper}>
          {error && (
            <Alert
              severity="error"
              onClose={() => {
                setError(null)
              }}
            >
              {error}
            </Alert>
          )}
          {success && (
            <Alert
              severity="success"
              onClose={() => {
                setSuccess(null)
              }}
            >
              {success}
            </Alert>
          )}
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orgUsers.map((orgUser) => (
                <TableRow
                  key={orgUser.id}
                  onClick={() => setSelectedUser(orgUser)}
                >
                  <TableCell>{orgUser.user.email}</TableCell>
                  <TableCell>
                    {orgUser.user.is_active ? "Active" : "Invited"}
                  </TableCell>
                  <TableCell>{getUserRole(orgUser)}</TableCell>
                  <TableCell>
                    <a href="#" onClick={handleUserEditOpen}>
                      <Icon type="edit" />
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal
        key={selectedUser?.id}
        isOpen={userEditOpen}
        onClose={handleUserEditClose}
        heading="Edit User"
      >
        <div className="column">
          <Form onSubmit={handleUserEditSubmit} className="rows">
            <Box m={1}>
              <strong>Email:</strong> {selectedUser?.user?.email}
            </Box>
            <input
              id={selectedUser?.id}
              name="userId"
              type="hidden"
              value={selectedUser?.id}
            />
            <Box m={1}>
              <Checkbox
                id="isAdmin"
                name="isAdmin"
                label="Role"
                variant="outlined"
                disabled={shouldDisableIsAdmin(selectedUser)}
                defaultChecked={selectedUser?.is_admin}
              />
              Data Owner
            </Box>
            <div className="form-action button-group">
              <div className="button-container">
                <Button
                  buttonStyle="inline"
                  size="small"
                  text="Cancel"
                  onClick={handleUserEditClose}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  text="Update"
                  className="dark"
                  disabled={shouldDisableIsAdmin(selectedUser)}
                  type="submit"
                >
                  Update
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        isOpen={userCreateOpen}
        onClose={handleUserCreateClose}
        heading="Add User"
        className="min-content"
      >
        <div className="column">
          <Form onSubmit={handleUserCreateSubmit} className="rows">
            <small>
              Invite a user to join your organization. If they don{"'"}t have an
              account, they will be invited to create one.
            </small>

            <TextField name="email" type="email" placeholder="Email" required />
            <div className="columns">
              <Checkbox
                id="isAdmin"
                name="isAdmin"
                label="Data Owner"
                variant="outlined"
              />
              Data Owner
            </div>

            <div className="form-action button-group">
              <div className="button-container">
                <Button
                  buttonStyle="inline"
                  size="small"
                  text="Cancel"
                  onClick={handleUserCreateClose}
                />
                <Button
                  size="small"
                  text="Add User"
                  className="dark"
                  type="submit"
                />
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}
