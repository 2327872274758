import React from "react"
import { getEnvironment } from "../lib/util"

import "./Message.css"

const checkUrl = () => {
  const url = window.location.pathname
  if (url === "/login") {
    return `Sign in to the Blind Insight ${getEnvironment()}.`
  } else if (url === "/register") {
    return `Sign up to access the Blind Insight ${getEnvironment()} environment.`
  }
}

function isRegister() {
  if (window.location.pathname === "/register") {
    return (
      <>
        <br />
        <br />
        Enter your organization and personal info to complete sign-up and access
        your account.
      </>
    )
  }
}

export default function Message() {
  return (
    <div className="message">
      <h1>Welcome to the Blind Insight {getEnvironment()}</h1>
      <p>
        {checkUrl()}
        {isRegister()}
        <br />
        <br />
        Accounts are free for 60 days - no credit card required.
        <br />
        <br />
        Free accounts are in a shared environment. Paid accounts receive their
        own private environment along with white-glove support.
        <br />
        <br />
        If you have any questions you can always reach out through the Intercom
        messenger at the bottom right of your screen, or see our{" "}
        <a
          href="https://docs.blindinsight.io/getting-started/"
          rel="noopener noreferrer"
          target="_blank"
        >
          {" "}
          Getting Started Guide.
        </a>
      </p>
    </div>
  )
}
