import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import { Api } from "../lib/api"
import { Alert } from "@mui/material"

// The URL to redirect to after activation succeeds.
const POST_ACTIVATION_REDIRECT_URL = "/datasets?product_tour_id=528227"

export default function AccountsActivate() {
  const [searchParams] = useSearchParams()
  const [activated, setActivated] = React.useState(false)
  const [error, setError] = React.useState(null)
  const api = new Api()

  const user_id = searchParams.get("user_id")
  const timestamp = searchParams.get("timestamp")
  const signature = searchParams.get("signature")

  React.useEffect(() => {
    if (!user_id || !timestamp || !signature) {
      setError("Invalid activation link. Please request a new one.")
      return
    }

    const activate = async () => {
      // const resp = await api.OrganizationInvitationAccept(orgId, invId)
      console.log("Sending activation request")
      const resp = await api.accountsVerifyRegistration(
        user_id,
        timestamp,
        signature,
      )
      return resp
    }

    activate()
      .then((resp) => {
        console.log("Activation success")
        setActivated(getSuccessMessage(resp))
      })
      .catch((error) => error.json().then((r) => setError(r.detail)))
  }, [user_id, timestamp, signature])

  const getSuccessMessage = (resp) => {
    return (
      <React.Fragment>
        <div>
          <p>{resp.detail}</p>
        </div>
        <div>
          <p>
            <Link to={POST_ACTIVATION_REDIRECT_URL}>Click here to login</Link>
          </p>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="container dark">
        <div className="section">
          <div className="message">
            <h1>Welcome to Blind Insight!</h1>

            <h4>Account activation</h4>

            <div>
              <p>
                <b>If you were responding to an Organization invitation:</b>
              </p>
              <p>
                Please check your email for a link to set your initial password.
              </p>
            </div>

            <div>
              <p>
                <b>If you self-registered</b>:
              </p>
              <p>
                You chose a password during registration and are good to go!
              </p>
            </div>

            <div className="full-width">
              {!activated && <p>Activating your account...</p>}
              {activated && <Alert severity="success">{activated}</Alert>}
              {error && <Alert severity="error">{error}</Alert>}
            </div>
          </div>
          <div className="form-container">
            <div className="logo-vertical" />
          </div>
        </div>
        <div className="footer dark full-width">
          &copy; 2024, <a href="https://blindinsight.com">Blind Insight</a> |{" "}
          <a href="https://docs.blindinsight.io">View docs</a>
        </div>
      </div>
    </React.Fragment>
  )
}
